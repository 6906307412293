import * as React from 'react'
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import AboutV2 from '../components/AboutV2';
import Articles from '../components/Articles';
import SiteFooter from '../components/SiteFooter';
import PageContainer from '../components/PageContainer';
import { ArticleInfo, MarkdownRemarkNode } from '../utils/ArticleInfos';

interface Props {
  data: Data
}

interface State { }

export default class IndexPage extends React.Component<Props, State> {
  render() {
    const articleInfos = this.props.data.allMarkdownRemark.edges.map(({ node }) => ArticleInfo.from(node))

    return (
      <PageContainer>
        <Hero />
        <AboutV2 />
        <Articles articleInfos={articleInfos} />
        <SiteFooter />
      </PageContainer>
    );
  }
}

interface Data {
  allMarkdownRemark: {
    edges: {
      node: MarkdownRemarkNode
    }[]
  }
}

export const query = graphql`
  query LandingPageQuery {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date
            short
          }
        }
      }
    }
  }
`
